
import { Component, Vue } from "vue-property-decorator";
import { ICommodity } from "@/types/Commodity";
import { PropType } from "vue";
import { Defaults } from "@/helpers/Defaults";
import downloadInstruction from "@/components/DownloadInstruction.vue";
const InstructionProps = Vue.extend({
  props: {
    modal: Object,
    instruction: Object,
    commodities: Array as PropType<ICommodity[]>,
  },
});
@Component({
  name: "ViewInstructionModal",
  components: {
    downloadInstruction,
  },
})
export default class ViewInstructionModal extends InstructionProps {
  defaults = new Defaults();
  commodity: ICommodity = this.defaults.commodity;
  getCommodityById(id: number): ICommodity {
    const commodities: ICommodity[] = this.commodities;
    const found = commodities.find((element) => element.id == id);
    if (found != undefined) return found || this.defaults.commodity;
    return this.defaults.commodity;
  }
  downloadInstruction(): void {
    console.log("Here!");
  }
  visitRoute(link: string): void {
    this.$router.push(link);
  }
}
