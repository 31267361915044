
import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";
import { IUser } from "@/types/User";
import { ITruckDriver } from "@/types/TruckDriver";
import { IInstruction } from "@/types/Instruction";
import { IInstructedDispatch } from "@/types/InstructedDispatch";
import { IInstructedCommodity } from "@/types/InstructedCommoditiy";
import { Defaults } from "@/helpers/Defaults";
import { ICommodity } from "@/types/Commodity";
import { IRequestedCommodity } from "@/types/RequestedCommodity";
import { IDispatchedCommodity } from "@/types/DispatchedCommodity";

const DispatchProps = Vue.extend({
  props: {
    modal: Object,
    driver: Object as PropType<ITruckDriver>,
    instruction: Object as PropType<IInstruction>,
    drivers: Array as PropType<ITruckDriver[]>,
    users: Array as PropType<IUser[]>,
    dispatch: Object as PropType<IInstructedDispatch>,
    commodities: Array as PropType<IInstructedCommodity[]>,
    dispatchOverlay: Object,
  },
});

@Component({
  name: "InstructedDispatchModal",
})
export default class instructedDispatchModal extends DispatchProps {
  newBalance = 0;
  tonnage = 0;
  defaults = new Defaults();
  reliefItems: IInstructedCommodity[] = [];
  maxQty: number[] = [];
  selectedCommodities: number[] = [];
  save(): void {
    this.dispatch.instructionId = this.instruction.id;
    let reliefItems = this.reliefItems;
    let dispatchedItems: IDispatchedCommodity[] = [];
    for (let item of reliefItems) {
      dispatchedItems.push({
        commodity: item.commodity,
        commodityId: item.commodityId,
        id: 0,
        instructedDispatch: this.defaults.instructedDispatch,
        instructedDispatchId: this.dispatch.id,
        quantity: item.quantity,
      });
      this.dispatch.dispatchedCommodities = dispatchedItems;
    }
    this.$emit("save:instructed-dispatch", this.dispatch);
  }
  MaxBalance(id: number) {
    let array = this.commodities;
    let found = array.find(
      (element: IInstructedCommodity) => element.commodityId == id
    );
    return found !== undefined ? found.balance : 0;
  }
  removeReliefItem(item: IRequestedCommodity, index: number): void {
    this.selectedCommodities = this.selectedCommodities.filter(
      (id) => id != item.commodityId
    );
    this.reliefItems.splice(index, 1);
  }
  changeDriver(): void {
    const name = this.dispatch.driver.name;
    const allDrivers = this.drivers;
    this.dispatch.driverId = 0;
    this.dispatch.driver.id = 0;
    if (Array.isArray(allDrivers)) {
      const selectedDriver = allDrivers.find((element) => element.name == name);
      if (selectedDriver != undefined) {
        this.dispatch.driver = selectedDriver;
        this.dispatch.driverId = selectedDriver.id;
      }
    }
  }
  changeLicence(): void {
    const licence = this.dispatch.driver.licence;
    const allDrivers = this.drivers;
    this.dispatch.driverId = 0;
    this.dispatch.driver.id = 0;
    if (Array.isArray(allDrivers)) {
      const selectedDrivers = allDrivers.filter(function (selectedDriver) {
        if (selectedDriver.licence == licence) {
          return selectedDriver.licence;
        }
      });
      if (selectedDrivers.length > 0) this.dispatch.driver = selectedDrivers[0];
    }
  }
  changeBalance(): void {
    const quantity = this.dispatch.quantity;
    const balance = 0;
    this.tonnage = quantity / 20;
    this.newBalance = balance - this.tonnage;
  }
  getUserById(id: string): string {
    const users: IUser[] = this.users;
    const found = users.find((element) => element.id == id);
    if (found != undefined) return found.userName || "unknown";
    return "unknown";
  }
}
