
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "ApproveInstructionModal",
  components: {},
  props: {
    modal: Object,
    approveInstruction: {},
  },
})
export default class approveInstructionModal extends Vue {}
