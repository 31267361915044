import axios from "axios";
import { IInstructedDispatch } from "@/types/InstructedDispatch";
import { IServiceResponse } from "@/types/ServiceResponse";
/*
 *InstructedDispatch service
 * Provides UI business logic for instructed Dispatch
 */
export class InstructedDispatchService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IServiceResponse> {
    try {
      const inclStr =
        '/?includes=["Driver", "Dispatcher","DispatchedCommodities","DispatchedCommodities.Commodity",' +
        '"Instruction","Instruction.Warehouse","Instruction.District"]';
      const result = await axios.get(
        `${this.API_URL}/InstructedDispatch${inclStr}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      //const dashboardFilters = new Filters();
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
  public async create(
    instructedDispatch: IInstructedDispatch
  ): Promise<IServiceResponse> {
    try {
      const result = await axios.post(
        `${this.API_URL}/InstructedDispatch`,
        instructedDispatch,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      console.log(error);
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async delete(id: number): Promise<IServiceResponse> {
    try {
      const result = await axios.get(
        `${this.API_URL}/InstructedDispatch/delete/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
  public async update(
    instructedDispatch: IInstructedDispatch
  ): Promise<IServiceResponse> {
    try {
      const result = await axios.post(
        `${this.API_URL}/InstructedDispatch/update`,
        instructedDispatch,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token") || "{}",
          },
        }
      );
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
