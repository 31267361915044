
import { Component, Vue } from "vue-property-decorator";
import { ICommodity } from "@/types/Commodity";
import { PropType } from "vue";
import { Defaults } from "@/helpers/Defaults";
const InstructionProps = Vue.extend({
  props: {
    instruction: Object,
    commodities: Array as PropType<ICommodity[]>,
  },
});
@Component({
  name: "DownloadInstruction",
  components: {},
})
export default class downloadInstruction extends InstructionProps {
  defaults = new Defaults();
  commodity: ICommodity = this.defaults.commodity;
  getCommodityById(id: number): ICommodity {
    const commodities: ICommodity[] = this.commodities;
    const found = commodities.find((element) => element.id == id);
    if (found != undefined) return found || this.defaults.commodity;
    return this.defaults.commodity;
  }
}
