
import { Component, Prop, Vue } from "vue-property-decorator";
const InstructionProps = Vue.extend({
  props: {
    modal: Object,
    edit: Object,
    instruction: Object,
    transporters: [],
    commodities: [],
    warehouses: [],
    districts: [],
  },
});
@Component({
  name: "EditInstructionModal",
  components: {},
})
export default class EditInstructionModal extends InstructionProps {
  name = "";

  update(): void {
    this.instruction.balance = this.balance;
    this.$emit("update:loading-plan", this.instruction);
  }
  get balance(): number {
    return +(this.instruction.quantity - this.modal.dispatched).toFixed(2);
  }
}
