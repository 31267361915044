
import { Component, Vue } from "vue-property-decorator";
import { InstructionService } from "@/services/instruction-service";
import { IInstruction } from "@/types/Instruction";
import { ITruckDriver } from "@/types/TruckDriver";
import { IDistrict } from "@/types/District";
import { IWarehouse } from "@/types/Warehouse";
import { ITransporter } from "@/types/Transporter";
import { ICommodity } from "@/types/Commodity";
import { IUser } from "@/types/User";
import newDispatchModal from "@/components/DispatchModal.vue";
import viewInstructionModal from "@/components/ViewInstructionModal.vue";
import confirmDeleteModal from "@/components/ConfirmDeleteModal.vue";
import editInstructionModal from "@/components/EditInstructionModal.vue";
import approveInstructionModal from "@/components/ApproveInstructionModal.vue";
import instructedDispatchModal from "@/components/InstructedDispatchModal.vue";
import { Defaults } from "@/helpers/Defaults";
import { Functions } from "@/helpers/Functions";
import { IDispatch } from "@/types/Dispatch";
import { CommodityService } from "@/services/commodity-service";
import { InstructedDispatchService } from "@/services/instructed-dispatch-service";
import { IInstructedDispatch } from "@/types/InstructedDispatch";
import { IResponseData } from "@/types/ResponseData";

@Component({
  name: "Instruction",
  components: {
    newDispatchModal,
    viewInstructionModal,
    confirmDeleteModal,
    editInstructionModal,
    approveInstructionModal,
    instructedDispatchModal,
  },
})
export default class Instruction extends Vue {
  show = { showOverlay: false }; //this is for new loading plan
  edit = { showOverlay: false }; // this is for loading plans
  dispatchOverlay = { show: false };
  confirmDelete = { showOverlay: false };
  approveInstructionOverlay = { showOverlay: false };
  userString = localStorage.getItem("user");
  isAdmin = false;
  defaults = new Defaults();
  functions = new Functions();
  instructionService = new InstructionService();
  commodityService = new CommodityService();
  instructedDispatchService = new InstructedDispatchService();
  instructionResponseData: IResponseData = this.defaults.responseData;
  myInstructions: IInstruction[] = [];
  users: IUser[] = [];
  drivers: ITruckDriver[] = [];
  districts: IDistrict[] = [];
  warehouses: IWarehouse[] = [];
  transporters: ITransporter[] = [];
  commodities: ICommodity[] = [];
  instructionsCount = 0;
  pageSize = 10;
  pageNumber = 1;
  instruction = this.defaults.instruction;
  dispatch = this.defaults.instructedDispatch;
  driver: ITruckDriver = {
    id: 0,
    name: "",
    licence: "",
    isArchived: false,
  };
  currentModal = {
    id: 0,
    name: "",
    showOverlay: false,
  };
  loading = true;
  instructionsSkeleton = true;
  async initialize(): Promise<void> {
    const includes =
      '["Warehouse","District","Transporter","User","InstructedCommodities","InstructedCommodities.Commodity"]';
    const instructionResponse = await this.instructionService.getAll(includes);
    if (!instructionResponse.isSuccess && instructionResponse.status == 401)
      this.visitRoute("/logout");
    this.instructionResponseData = instructionResponse.data;
    this.instruction = this.instructionResponseData.data[0];
    this.loading = false;
    this.instructionsSkeleton = false;
    this.commodities = await this.commodityService.getAll();
  }
  async created(): Promise<void> {
    this.checkIfAdmin();
    await this.initialize();
  }
  checkIfAdmin(): void {
    if (this.userString != null) {
      const user = JSON.parse(this.userString);
      const userRoles = user.roles;
      if (userRoles.includes("admin")) {
        this.isAdmin = true;
      }
    }
  }
  closeModal(id: number): void {
    const modal = this.deleteInstructionModalId(id);
    this.$bvModal.hide(modal.name);
  }
  closeApprovalModal(id: number): void {
    const modal = this.approveInstructionModalId(id);
    this.$bvModal.hide(modal.name);
  }
  visitRoute(link: string): void {
    this.$router.push(link);
  }
  dispatchModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "createInstructionDispatchModal" + i,
      showOverlay: false,
    };
  }
  viewInstructionModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "viewInstructionModal" + i,
      showOverlay: false,
    };
  }
  deleteInstructionModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "deleteInstructionModal" + i,
      showOverlay: false,
    };
  }
  approveInstructionModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "approveInstructionModal" + i,
      showOverlay: false,
    };
  }
  editInstructionModalId(id: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: id,
      name: "editInstructionModal" + id,
      showOverlay: false,
    };
  }
  async deleteInstruction(id: number): Promise<void> {
    if (this.isAdmin) {
      this.confirmDelete.showOverlay = true;
      const result = await this.instructionService.delete(id);
      this.confirmDelete.showOverlay = false;
      this.$bvModal.hide(this.deleteInstructionModalId(id).name);
      let message = result.message;
      let title = "Deleting Instruction";
      let variant = "danger";
      if (result.isSuccess) {
        variant = "success";
        await this.initialize();
      }
      this.makeToast(message, title, variant);
    } else {
      this.$bvModal.hide(this.deleteInstructionModalId(id).name);
      let message = "You can't update this resource!";
      let title = "Deleting Instruction";
      let variant = "danger";
      this.makeToast(message, title, variant);
    }
  }
  async approveInstruction(id: number): Promise<void> {
    if (this.isAdmin) {
      this.approveInstructionOverlay.showOverlay = true;
      const result = await this.instructionService.approve(id);
      this.approveInstructionOverlay.showOverlay = false;
      this.$bvModal.hide(this.approveInstructionModalId(id).name);
      let message = result.message;
      let title = "Approving Instruction";
      let variant = "danger";
      if (result.isSuccess) {
        variant = "success";
        await this.initialize();
      }
      this.makeToast(message, title, variant);
    } else {
      this.$bvModal.hide(this.approveInstructionModalId(id).name);
      let message = "You can't update this resource!";
      let title = "Approving Instruction";
      let variant = "danger";
      this.makeToast(message, title, variant);
    }
  }
  updateInstruction(instruction: IInstruction): void {
    console.log(instruction);
  }
  async submitNewDispatch(dispatch: IInstructedDispatch): Promise<void> {
    this.currentModal = this.dispatchModalId(dispatch.instructionId);
    this.dispatchOverlay.show = true;
    const result = await this.instructedDispatchService.create(dispatch);
    this.dispatchOverlay.show = false;
    this.$bvModal.hide(this.currentModal.name);
    let message = result.message;
    let title = "Creating a new Dispatch";
    let variant = "danger";
    if (result.isSuccess) {
      this.dispatch = this.defaults.instructedDispatch;
      variant = "success";
    }
    this.makeToast(message, title, variant);
  }
  makeToast(
    message = "Oh Snap! something went wrong. Please try again later.",
    title = "",
    variant = "danger"
  ): void {
    this.$bvToast.toast(message, {
      title: title,
      autoHideDelay: 5000,
      appendToast: true,
      variant: variant,
    });
  }
  prepend(value: any, array: Array<any>): Array<any> {
    let newArray = array.slice();
    newArray.unshift(value);
    return newArray;
  }
}
